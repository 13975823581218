<template lang="pug">
  div.tools-page
    div.container
      h1 {{ $t('tools.featuresTitle') }}
      section
        div.tools-page__info.tools-page__info-deco
          h3 {{ $t('tools.onuRegistrationTitle') }}
          ul(v-html="$t('tools.onuRegistrationList')").list-standard
        div.tools-page__img
          img(
            :src="RegOnu"
            alt="reg onu"
          )
      section
        div.tools-page__info
          h3 {{ $t('tools.usefulFeaturesTitle') }}
          p.tools-page__text {{ $t('tools.usefulFeaturesText') }}
          ul.list-standard--inside
            div(v-html="$t('tools.usefulFeaturesList')")
          p {{ $t('tools.monitoringFunctionText') }}
          p {{ $t('tools.identifyingIssuesText') }}
        div.tools-page__img
          img(
            :src="UsefulHard"
            alt="useful hardware & network monitoring features"
          )
      section
        div.tools-page__info
          h3 {{ $t('tools.integrationTitle') }}
          div.decor-arrow
            p(v-html="$t('tools.googleAuthorizationText')")
            p(v-html="$t('tools.usersideText')")
        div.tools-page__img.tools-page__img-center
          img(
            :src="Integration"
            alt="integration with various systems"
          )
      section
        div.tools-page__info
          h3 {{ $t('tools.visualMapTitle') }}
          p {{ $t('tools.mapFunctionalityText') }}
          p {{ $t('tools.comprehensiveManagementText') }}
          ul.list-standard--inside
            div(v-html="$t('tools.comprehensiveManagementList')")
        div.tools-page__img
          img(
            :src="Visual"
            alt="visual map of network"
          )
      section
        div.tools-page__info.tools-page__info-wide
          h3 {{ $t('tools.permissionsSettingsTitle') }}
          div.tools-page__wrap
            p {{ $t('tools.permissionsSettingsText') }}
            p {{ $t('tools.groupPermissionsText') }}
        div.tools-page__img
          img(
            :src="Flexible"
            alt="flexible permissions settings"
          )
      section
        div.tools-page__info
          h3 {{ $t('tools.graphStatsTitle') }}
          p {{ $t('tools.uplinkGraphsText') }}
          p {{ $t('tools.correctDisplayText') }}
          p {{ $t('tools.everyGraphText') }}
          p {{ $t('tools.greatPossibilityText') }}
        div.tools-page__img
          img(
            :src="Graph"
            alt="graph stats"
          )
</template>

<script>
import RegOnu from '@/assets/images/regonu.svg'
import UsefulHard from '@/assets/images/useful-hard.svg'
import Integration from '@/assets/images/integration.svg'
import Visual from '@/assets/images/visual.svg'
import Flexible from '@/assets/images/flexible.svg'
import Graph from '@/assets/images/graph.svg'

export default {
  name: 'ToolsPage',
  metaInfo () {
    return this.$metaDefault()
  },
  data () {
    return {
      RegOnu,
      UsefulHard,
      Integration,
      Visual,
      Flexible,
      Graph
    }
  }
}
</script>
